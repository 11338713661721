<template>
  <div class="wrapper">
    <slot />
    <ModalsContainer />
  </div>
</template>

<script>
import { ModalsContainer } from 'vue-final-modal';
export default {
  components: {
    ModalsContainer,
  },
  setup(props, context) {
    return {};
  },
};
</script>

<style lang="scss" scoped>
body {
  margin: 0px;
}
</style>
